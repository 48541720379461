// src/Modal.js
import React from "react";
import "./Modal.css";

const Modal1 = ({ isOpen, onClose, score, image, scoreText }) => {
  if (!isOpen) return null;

  const handleContextMenu = (e) => {
    e.preventDefault();
  };
  console.log(image);
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>{scoreText}</h2>
        <p></p>
        <img
          onContextMenu={handleContextMenu}
          src={(score < 5 && "/2.jpeg") || "/1.jpeg"}
          alt="Game Over"
          width="250"
          height="250"
        />
        <button onClick={onClose}>Click here to play</button>
      </div>
    </div>
  );
};

export default Modal1;
