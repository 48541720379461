import React, { useEffect, useRef, useState } from "react";
import "./ShootingGame.css";

const ShootingGame = () => {
  const canvasRef = useRef(null);
  const [player, setPlayer] = useState({ x: 140, y: 350 });
  const [bullets, setBullets] = useState([]);
  const [balloons, setBalloons] = useState([]);
  const [score, setScore] = useState(0);
  const [randomNo, setRandomNo] = useState(Math.floor(Math.random() * 4) + 1);
  const [gameOver, setGameOver] = useState(false);

  const canvasWidth = 300;
  const canvasHeight = 400;

  const resetGame = () => {
    setPlayer({ x: 140, y: 350 });
    setBullets([]);
    setBalloons([]);
    setScore(0);
    setGameOver(false);
  };

  const movePlayer = (direction) => {
    setPlayer((prev) => {
      const newX = direction === "left" ? prev.x - 20 : prev.x + 20;
      return { ...prev, x: Math.max(0, Math.min(canvasWidth - 20, newX)) };
    });
  };

  const shootBullet = () => {
    setBullets((prev) => [...prev, { x: player.x + 8, y: player.y }]);
  };

  const updateGame = () => {
    if (!gameOver) {
      setBullets((prev) =>
        prev.filter((b) => b.y > 0).map((b) => ({ ...b, y: b.y - 10 }))
      );

      setBalloons((prev) =>
        prev
          .filter((b) => b.y < canvasHeight)
          .map((b) => ({ ...b, y: b.y + 5 }))
      );

      setBullets((prevBullets) =>
        prevBullets.filter((bullet) => {
          const hitIndex = balloons.findIndex(
            (balloon) =>
              bullet.x > balloon.x &&
              bullet.x < balloon.x + 20 &&
              bullet.y > balloon.y &&
              bullet.y < balloon.y + 20
          );
          if (hitIndex !== -1) {
            setBalloons((prevBalloons) =>
              prevBalloons.filter((_, i) => i !== hitIndex)
            );
            setScore((prevScore) => prevScore + 1);
            return false;
          }
          return true;
        })
      );

      if (balloons.some((b) => b.y >= canvasHeight)) {
        setGameOver(true);
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!gameOver) {
        if (Math.random() < 0.05) {
          setBalloons((prev) => [
            ...prev,
            { x: Math.random() * (canvasWidth - 20), y: 0 },
          ]);
        }
        updateGame();
      }
    }, 100);

    return () => clearInterval(interval);
  }, [balloons, gameOver]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    ctx.clearRect(0, 0, canvasWidth, canvasHeight);
    ctx.fillStyle = "black";
    ctx.fillRect(0, 0, canvasWidth, canvasHeight);

    ctx.fillStyle = "gray";
    ctx.fillRect(player.x, player.y, 20, 20);
    ctx.fillStyle = "blue";
    ctx.fillRect(player.x + 7, player.y - 10, 6, 10);

    ctx.fillStyle = "yellow";
    bullets.forEach((bullet) => {
      ctx.fillRect(bullet.x, bullet.y, 4, 10);
    });

    const balloonColors = ["red", "green", "blue", "purple", "pink"];
    balloons.forEach((balloon, index) => {
      ctx.fillStyle = balloonColors[index % balloonColors.length];
      ctx.beginPath();
      ctx.ellipse(balloon.x + 10, balloon.y + 10, 10, 15, 0, 0, 2 * Math.PI);
      ctx.fill();
    });

    ctx.fillStyle = "white";
    ctx.font = "16px Arial";
    ctx.fillText(`Score: ${score}`, 10, 20);

    if (gameOver) {
      ctx.fillStyle = "red";
      ctx.font = "24px Arial";
      ctx.fillText("Game Over", canvasWidth / 2 - 60, canvasHeight / 2);
    }
  }, [player, bullets, balloons, score, gameOver]);

  const getImageSrc = () => {
    if (score < 3) return "shootinggame/0.mp4";
    if (score >= 3 && score < 6) return "shootinggame/1.mp4";
    if (score >= 6 && score < 9) return "shootinggame/2.mp4";
    if (score >= 9 && score < 12) return "shootinggame/3.mp4";
    if (score >= 12 && score < 15) return "shootinggame/4.mp4";
    if (score >= 15) return "shootinggame/5.mp4";
    return "";
  }; 

  return (
    <div className="game-container">
      <h4 className="header">{"Play with aunty \u{1F609}"}</h4>
      <canvas
        ref={canvasRef}
        width={canvasWidth}
        height={canvasHeight}
      ></canvas>
      <div className="controls-wrapper">
        <div className="row-buttons">
          <button onClick={() => movePlayer("left")}>Left</button>
          <button onClick={shootBullet}>Shoot</button>
          <button onClick={() => movePlayer("right")}>Right</button>
        </div>
        <button className="reset-button" onClick={resetGame}>
          Reset Game
        </button>
      </div>
      {/* <div className="img-placeholder">
        {getImageSrc() && <img src={getImageSrc()} alt="Score Image" />}
      </div> */}
      <div className="img-placeholder">
        {getImageSrc() && (
          <video
            src={getImageSrc()}
            controls
            autoPlay
            loop
            controlsList="nodownload"
          >
            Your browser does not support the video tag.
          </video>
        )}
      </div>
    </div>
  );
};

export default ShootingGame;
