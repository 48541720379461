import React, { useState, useEffect } from "react";
import Post from "./Post";
const Feed = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch("https://api.npoint.io/71b1e242c258bb947038") // Fetching from the public folder
      .then((response) => response.json())
      .then((data) => {
        setPosts(data);
        setLoading(false);
      })
      .catch((error) => console.error("Error fetching JSON:", error));
  }, []);

  return (
    <div
      style={{
        fontFamily: "'Arial', sans-serif",
        textAlign: "center",
        height: "100vh",
        margin: 0,
        padding: "20px",
      }}
    >
      <h1>Hot Aunties...</h1>
      {loading ? (
        <p>Loading...</p>
      ) : (
        posts.map((post, index) => <Post key={index} image={post} />)
      )}
    </div>
  );
};

export default Feed;
