import React, { useState, useRef } from "react";
import { FaHeart, FaRegHeart, FaShareAlt } from "react-icons/fa";

const Post = ({ title, description, image }) => {
  const [liked, setLiked] = useState(false);
  const [shareCount, setShareCount] = useState(0);
  const postRef = useRef(null);

  const likeSound = "/1.mp3";
  const audio = new Audio(); // Create an Audio object

  const handleLike = () => {
    setLiked(!liked);
    if (!liked) {
      audio.src = likeSound;
      audio.play();
    }
  };

  const handleShare = () => {
    setShareCount(shareCount + 1);
    audio.src = likeSound;
    audio.play();
  };

  const handleDoubleTap = (e) => {
    if (e.detail === 2) {
      handleLike();
    }
  };

  const preventDownload = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <style>{responsiveStyles}</style>
      <div
        ref={postRef}
        style={styles.post}
        onDoubleClick={handleDoubleTap}
        onContextMenu={preventDownload}
      >
        <img
          src={image}
          alt="Post"
          style={styles.image}
          onDragStart={(e) => e.preventDefault()}
        />
        <div style={styles.postContent}>
          <h3 style={styles.title}>{title}</h3>
          <p style={styles.description}>{description}</p>
          <div style={styles.postActions}>
            <button onClick={handleLike} style={styles.actionButton}>
              {liked ? (
                <FaHeart style={{ color: "red", fontSize: "18px" }} />
              ) : (
                <FaRegHeart style={{ fontSize: "18px" }} />
              )}
              <span style={styles.actionText}>{liked ? "Liked" : "Like"}</span>
            </button>
            {/* <button onClick={handleShare} style={styles.actionButton}>
              <FaShareAlt style={{ fontSize: "18px" }} />
              <span style={styles.actionText}>Share</span>
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  post: {
    border: "1px solid #ccc",
    borderRadius: "12px",
    padding: "16px",
    margin: "16px 0",
    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    userSelect: "none",
  },
  image: {
    width: "100%",
    maxWidth: "300px",
    height: "auto",
    borderRadius: "12px",
    marginBottom: "16px",
    userSelect: "none",
  },
  postContent: {
    textAlign: "center",
  },
  title: {
    fontSize: "20px",
    fontWeight: "600",
    marginBottom: "8px",
  },
  description: {
    fontSize: "16px",
    color: "#555",
    marginBottom: "16px",
  },
  postActions: {
    display: "flex",
    gap: "16px",
    justifyContent: "center",
    alignItems: "center",
  },
  actionButton: {
    border: "none",
    background: "transparent",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    transition: "transform 0.2s",
  },
  actionButtonHover: {
    transform: "scale(1.1)",
  },
  actionText: {
    marginLeft: "8px",
    fontSize: "14px",
    color: "#333",
  },
};

const responsiveStyles = `
  @media (max-width: 768px) {
    .post {
      padding: 12px;
      margin: 12px 0;
    }
    .image {
      max-height: 200px;
    }
    .actionText {
      font-size: 12px;
    }
  }
`;

export default Post;
