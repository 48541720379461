import React, { useState, useEffect } from "react";

const Christmas = () => {
  const [images, setImages] = useState([1, 2, 3, 4, 5]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div>
      <h1
        style={{
          fontSize: "2rem",
          color: "#ff0000",
          textShadow: "2px 2px 4px rgba(0,0,0,0.3)",
          marginBottom: "16px",
          textAlign: "center",
          width: "100%",
        }}
      >
        Merry Christmas!
      </h1>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "16px",
        }}
      >
        <div
          style={{
            width: "100%",
            maxWidth: "400px",
            position: "relative",
            overflow: "hidden",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
          }}
        >
          {images.map((image, index) => (
            <img
              key={index}
              src={`/christmas/${index + 1}.jpeg`}
              alt={`Image ${index + 1}`}
              style={{
                display: currentIndex === index ? "block" : "none",
                width: "100%",
                height: "75%",
                transition: "opacity 0.2s ease-in-out",
                objectFit: "fill",
              }}
            />
          ))}
        </div>

        <div
          style={{
            display: "flex",
            gap: "8px",
            marginTop: "16px",
          }}
        >
          {images.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              style={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                backgroundColor: currentIndex === index ? "#333" : "#ccc",
                border: "none",
                cursor: "pointer",
              }}
            ></button>
          ))}
        </div>

        {/* <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "16px",
            padding: "16px",
          }}
        >
          {images.map((image, index) => (
            <img
              key={index}
              src={`/christmas/${index + 1}.jpeg`}
              alt={`Thumbnail ${index + 1}`}
              style={{
                width: "100px",
                height: "auto",
                borderRadius: "4px",
                boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                cursor: "pointer",
              }}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default Christmas;
