import React from "react";
import { Link } from "react-router-dom";
import "./Home.css";

const Home = () => {
  return (
    <div
      style={{
        fontFamily: "'Arial', sans-serif",
        textAlign: "center",
        backgroundColor: "#f7f7f7",
        height: "100vh",
        margin: 0,
        padding: "20px",
      }}
    >
      {/* Header */}
      <h1
        style={{
          fontSize: "2.5rem",
          color: "#333",
          marginBottom: "20px",
          textTransform: "uppercase",
        }}
      >
        Play Me, Bro
      </h1>

      {/* Card Container */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: "20px",
          padding: "20px",
        }}
      >
        {/* Card 1 */}
        {/* <div
          style={{
            border: "2px solid #28A745",
            borderRadius: "10px",
            padding: "20px",
            width: "200px",
            textAlign: "center",
            backgroundColor: "#fff",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            transition: "transform 0.3s ease, box-shadow 0.3s ease",
            cursor: "pointer",
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.transform = "scale(1.05)";
            e.currentTarget.style.boxShadow = "0 8px 16px rgba(0, 0, 0, 0.2)";
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.transform = "scale(1)";
            e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)";
          }}
        >
          <Link
            to="/grow-your-snake"
            style={{
              textDecoration: "none",
              color: "#28A745",
              fontWeight: "bold",
            }}
          >
            <h3>Grow Your Snake</h3>
            <p style={{ color: "#555" }}>
              Score 10 points to unlock the sexy image - click here
            </p>
          </Link>
        </div> */}
        <div
          style={{
            border: "2px solid #28A745",
            borderRadius: "10px",
            padding: "20px",
            width: "200px",
            textAlign: "center",
            backgroundColor: "#fff",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            transition: "transform 0.3s ease, box-shadow 0.3s ease",
            cursor: "pointer",
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.transform = "scale(1.05)";
            e.currentTarget.style.boxShadow = "0 8px 16px rgba(0, 0, 0, 0.2)";
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.transform = "scale(1)";
            e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)";
          }}
        >
          <Link
            to="/feed"
            style={{
              textDecoration: "none",
              color: "#28A745",
              fontWeight: "bold",
            }}
          >
            <h3>Hot Aunties images..</h3>
            <p style={{ color: "#555" }}>click here</p>
          </Link>
        </div>
        {/* Card 2 */}
        <div
          style={{
            border: "2px solid #007BFF",
            borderRadius: "10px",
            padding: "20px",
            width: "200px",
            textAlign: "center",
            backgroundColor: "#fff",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            transition: "transform 0.3s ease, box-shadow 0.3s ease",
            cursor: "pointer",
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.transform = "scale(1.05)";
            e.currentTarget.style.boxShadow = "0 8px 16px rgba(0, 0, 0, 0.2)";
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.transform = "scale(1)";
            e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)";
          }}
        >
          <Link
            to="/shoot-to-remove"
            style={{
              textDecoration: "none",
              color: "#007BFF",
              fontWeight: "bold",
            }}
          >
            <h3>Play with aunty</h3>
            <p style={{ color: "#555" }}>
              Description : 3 points to remove pallu, 6 points to press hips, 9
              points to press boobs, 12 points to press hips and boobs, 15
              points to press boobs without jacket.
            </p>
            <p style={{ color: "#555" }}>Only age 18+ should enter</p>
            <p style={{ color: "#007BFF" }}>click here to play</p>
          </Link>
        </div>
        {/* Card 3 */}
        <div
          style={{
            border: "2px solid #28A745",
            borderRadius: "10px",
            padding: "20px",
            width: "200px",
            textAlign: "center",
            backgroundColor: "#fff",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            transition: "transform 0.3s ease, box-shadow 0.3s ease",
            cursor: "pointer",
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.transform = "scale(1.05)";
            e.currentTarget.style.boxShadow = "0 8px 16px rgba(0, 0, 0, 0.2)";
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.transform = "scale(1)";
            e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)";
          }}
        >
          <Link
            to="/story/story-1"
            style={{
              textDecoration: "none",
              color: "#28A745",
              fontWeight: "bold",
            }}
          >
            <h3>Sexy stories</h3>
            <p style={{ color: "#555" }}>click here</p>
          </Link>
        </div>
        <div>
          <Link
            to="/merry-christmas"
            style={{
              textDecoration: "none",
              color: "#28A745",
              fontWeight: "bold",
            }}
          >
            <h1 class="gift-text">CLICK TO SEE YOUR CHRISTMAS GIFT!</h1>
            <section class="gift">
              <div class="gift-top hovered"></div>
              <div class="gift-bottom"></div>
            </section>
            <h1 class="gift-final-text">MERRY CHRISTMAS!!!</h1>
          </Link>
        </div>
      </div>

      {/* Inline Media Query for Responsiveness */}
      <style>
        {`
          @media (max-width: 768px) {
            h1 {
              font-size: 2rem;
            }

            div[style*="200px"] {
              width: 100%;
              max-width: 300px;
              margin: 0 auto;
            }

            div[style*="center, gap: 20px"] {
              flex-direction: column;
            }
          }

          @media (max-width: 480px) {
            h1 {
              font-size: 1.5rem;
            }

            div[style*="padding: 20px"] {
              padding: 10px;
            }

            div[style*="200px"] {
              padding: 15px;
              font-size: 0.9rem;
            }
          }
        `}
      </style>
    </div>
  );
};

export default Home;
